import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import dotNotationMapper from '../../data/dotNotationMapper';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true,
            skipList : ['iterationId']
        });

        if(parsedParameters.error){
            variableObject[variableName] = {
                error : {
                    message : 'Failed to parse function data.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        let queryKey = `${Math.random()}${Math.random()}${Math.random()}${Math.random()}`;
        let payload = {
            environment : appEnvironment,
            assetOwnerCompanyId : companyId,
            functionGenerated : true,
            queryKey,
            encryptedKey : encryptDecrypt(queryKey, true),
            name : resolvedParams['name'] || '',
            projectId : resolvedParams['projectId'] || '',
            categoryId : resolvedParams['categoryId'] || '',
            metaData : resolvedParams['metaData'] || {},
            currency : resolvedParams['currency'] || '',
            paymentGatewayId : resolvedParams['paymentGatewayId'] || '',
            invoiceNumber : resolvedParams['invoiceNumber'] || '',
            purchaseOrderNumber : resolvedParams['purchaseOrderNumber'] || '',
            uniqueKeyId : resolvedParams['uniqueKeyId'] || '',
            hasSendSchedule : resolvedParams['hasSendSchedule'] || false,
            sendScheduleTime : resolvedParams['sendScheduleTime'] || '',
            sendScheduleTimezone : resolvedParams['sendScheduleTimezone'] || '',
            fileStorageServiceId : resolvedParams['fileStorageServiceId'] || ''
        }

        let distributionList = [], invalidItems = false;
        if(Array.isArray(resolvedParams['distributionList'])){
            for (let i = 0; i < resolvedParams['distributionList'].length; i++){
                let item = resolvedParams['distributionList'][i];
                let newObj = {
                    accountType : dotNotationMapper(item, resolvedParams['accountTypeField']),
                    amount : dotNotationMapper(item, resolvedParams['amountField']),
                    email : dotNotationMapper(item, resolvedParams['emailField']),
                    subscriptionAccountId : dotNotationMapper(item, resolvedParams['subscriptionAccountIdField']),
                    paymentDistributionAccountId : dotNotationMapper(item, resolvedParams['distributionAccountIdField']),
                }

                if(
                    !newObj['accountType'] ||
                    !newObj['amount'] ||
                    typeof newObj['accountType'] !== 'string' ||
                    typeof newObj['amount'] !== 'number'
                ){
                    invalidItems = true;
                    break;
                }

                distributionList.push(newObj);
            }
        }

        if(distributionList.length === 0){
            invalidItems = true;
        }

        if(invalidItems){
            variableCopy[variableName] = {
                error : {
                    message : 'Invalid distributionList payload.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Invalid distributionList payload.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        payload['distributionList'] = distributionList;

        let fileList = resolvedParams['fileList'] || [], 
        invalidFiles=false, files=[];
        if(fileList.length > 0){
            for (let i = 0; i < fileList.length; i++){
                let fileListObj = fileList[i];
                let newObj = {
                    fileName : dotNotationMapper(fileListObj, resolvedParams['fileNameField']),
                    fileType : dotNotationMapper(fileListObj, resolvedParams['fileTypeField']),
                    base64 : dotNotationMapper(fileListObj, resolvedParams['fileBase64Field'])
                }

                if(
                    !newObj['fileName'] ||
                    !newObj['fileType'] ||
                    !newObj['base64']
                ){
                    invalidFiles = true;
                    break;
                }

                files.push(newObj)
            }
        }

        if(invalidFiles){
            variableCopy[variableName] = {
                error : {
                    message : 'Invalid files. Please map files properly. Files must be mapped for 3 fields, fileName, fileType and base64.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Invalid files. Please map files properly. Files must be mapped for 3 fields, fileName, fileType and base64.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let encryptedAccessHeader = '', clientEncryptedApiHeader='';
        if(resolvedParams['X-API-KEY']){
            clientEncryptedApiHeader = encryptDecrypt(
                JSON.stringify({
                    payload : {
                        'X-API-KEY' : resolvedParams['X-API-KEY'],
                        companyId,
                        queryKey : `${Math.random()}${Math.random()}${Math.random()}${new Date().getTime()}`,
                        environment : appEnvironment
                    },
                    expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                }),
                true
            );
        }else{
            if(user && user.isAuthenticated){
                encryptedAccessHeader = encryptDecrypt(
                    JSON.stringify({
                        payload : {
                            assetOwnerCompanyId : companyId,
                            companyId : user['companyId'],
                            email : user['email'],
                            subscriptionId : user['subscriptionId'],
                            subscriptionServiceId : user['subscriptionServiceId'],
                            environment : appEnvironment,
                            _id : user['_id'],
                            userId : user['_id'],
                            isInternal : (user['subscriberType'] === 'internal') || user['isInternal'] ? true : false,
                            isSystemUser : user['isSystemUser'] ? true : false
                        },
                        expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                    }),
                    true
                );
            }
        }

        let response = await fetch(
            `${config.devServerProxy}/api/paymentgateway/distribution/distribute/run`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                    'clientEncryptedAccessHeader' : encryptedAccessHeader,
                    'X-CE-API-KEY' : clientEncryptedApiHeader
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload,
                    files
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/batchPaymentDistributionParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}