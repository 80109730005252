import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;

        let resolvedProductIds = await resolveExpression(
            executionObject['productIds'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let productIds = resolvedProductIds.value;
        variableCopy = resolvedProductIds.variableObject;

        let resolvedName = await resolveExpression(
            executionObject['name'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let name = resolvedName.value;
        variableCopy = resolvedName.variableObject;

        let resolvedDescription = await resolveExpression(
            executionObject['description'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let description = resolvedDescription.value;
        variableCopy = resolvedDescription.variableObject;

        let resolvedPricingType = await resolveExpression(
            executionObject['pricingType'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let pricingType = resolvedPricingType.value;
        variableCopy = resolvedPricingType.variableObject;

        let resolvedSKU = await resolveExpression(
            executionObject['SKU'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let SKU = resolvedSKU.value;
        variableCopy = resolvedSKU.variableObject;

        let resolvedSkip = await resolveExpression(
            executionObject['skip'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let skip = resolvedSkip.value;
        variableCopy = resolvedSkip.variableObject;

        let resolvedLimit = await resolveExpression(
            executionObject['limit'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let limit = resolvedLimit.value;
        variableCopy = resolvedLimit.variableObject;

        let resolvedActive = await resolveExpression(
            executionObject['active'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let active = resolvedActive.value;
        variableCopy = resolvedActive.variableObject;

        let filter = {};
        if(
            Array.isArray(productIds) && 
            productIds.length > 0
        ){
            filter['productIds'] = productIds;
        }

        if(name){
            filter['name'] = name;
        }
        if(description){
            filter['description'] = description;
        }
        if(pricingType){
            filter['pricingType'] = pricingType;
        }
        if(SKU){
            filter['SKU'] = SKU;
        }
        if(typeof active === 'boolean'){
            filter['active'] = active;
        }

        let payload = {
            filter,
            companyId,
            sort : {
                name : 1
            },
            functionType : 'productSearch'
        }
        if(typeof skip === 'number'){
            payload['skip'] = skip;
        }
        if(typeof limit === 'number'){
            payload['limit'] = limit;
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/inhouzcommerce/product/getproductserverquery`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(response['error']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed while searching for products.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        variableCopy[variableName] = response['results'] || [];
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/productSearchParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}