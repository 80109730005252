import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import dotNotationMapper from '../../data/dotNotationMapper';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
            dataToEnrich={}, emailField={}
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;
        let resolvedDataToEnrich = await resolveExpression(
            dataToEnrich,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let subscriberList = resolvedDataToEnrich.value;
        variableCopy = resolvedDataToEnrich.variableObject;

        if(
            !Array.isArray(subscriberList)
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Data to enrich must be an array of objects.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedEmailField = await resolveExpression(
            emailField,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let emailIdPath = resolvedEmailField.value;
        variableCopy = resolvedEmailField.variableObject;

        if(
            !emailIdPath ||
            typeof emailIdPath !== 'string'
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Email field or path is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let emailList = [];
        for (let i = 0; i < subscriberList.length; i++){
            let subscriberObj = subscriberList[i];
            let email = dotNotationMapper(subscriberObj, emailIdPath);
            if(
                email && 
                typeof email === 'string'
            ){
                emailList.push(email);
            }
        }

        if(emailList === 0){
            variableCopy[variableName] = subscriberList;
            return {
                hasError : false,
                errorPayload : undefined,
                hasReturnStatement : false,
                returnStatement : undefined,
                updatedVariableObject : variableCopy
            }
        }

        let payload = {
            assetOwnerCompanyId : companyId,
            emailList,
            functionType : 'enrichPaymentSubscriberData',
            environment : appEnvironment
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/inhouzpay/payment/subscription/paymentsubscriberquery`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(response['error']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to enrich subscriber data.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedSubscribers = response['results'] || [], dataMap={};
        for (let i = 0; i < resolvedSubscribers.length; i++){
            let subscriberObj = resolvedSubscribers[i];
            dataMap[subscriberObj['email']] = subscriberObj;
        }

        let enrichedData = [];
        for (let i = 0; i < subscriberList.length; i++){
            let dataObj = subscriberList[i];
            let email = dotNotationMapper(dataObj, emailIdPath);
            let subscriberObj = dataMap[email] || {};
            enrichedData.push({
                ...dataObj,
                ...subscriberObj
            });
        }

        variableCopy[variableName] = enrichedData;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/enrichPaymentSubscriberDataParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}