import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import validator from 'validator';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        let variableCopy = parsedParameters['variableObject'];
        if(parsedParameters.error){
            variableCopy[variableName] = {
                error : {
                    message : 'Failed to parse function parameters.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedParams = parsedParameters['accumulator'];
        let payload = {
            emailList : resolvedParams['emailList'] || [],
            subscriptionServiceId : resolvedParams['subscriptionServiceId'] || '',
            subscriptionServiceTierId : resolvedParams['subscriptionServiceTierId'] || ''
        }

        let cleanEmailList = [];
        for (let i = 0; i < payload['emailList'].length; i++){
            let email = payload['emailList'][i];
            if(validator.isEmail(email)){
                cleanEmailList.push(email);
            }
        }
        if(cleanEmailList.length === 0){
            variableCopy[variableName] = {
                error : {
                    message : 'Empty email list. A list of valid email(s) is required.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Empty email list. A list of valid email(s) is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        payload['emailList'] = cleanEmailList;

        if(
            !payload['subscriptionServiceId'] ||
            (typeof payload['subscriptionServiceId'] !== 'string')
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'Subscription Service ID is required.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Subscription Service ID is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !payload['subscriptionServiceTierId'] ||
            (typeof payload['subscriptionServiceTierId'] !== 'string')
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'Subscription Service Plan ID is required.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Subscription Service Plan ID is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(!resolvedParams['X-API-KEY']){
            variableCopy[variableName] = {
                error : {
                    message : 'API key is missing.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'API key is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let clientEncryptedApiHeader = encryptDecrypt(
            JSON.stringify({
                payload : {
                    'X-API-KEY' : resolvedParams['X-API-KEY'],
                    companyId,
                    queryKey : `${Math.random()}${Math.random()}${Math.random()}${new Date().getTime()}`,
                    environment : appEnvironment
                },
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/subscriber/automatecancelsubscription`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                    'X-CE-API-KEY' : clientEncryptedApiHeader
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/cancelSubscriptionServiceInternalUsersParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}