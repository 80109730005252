import simpleScriptParser from '../simpleScript/simpleScriptParser';
import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import postRequest from '../../requests/postRequest';
import config from '../../../config/config';
import moment from 'moment';
import encryptDecrypt from '../../cryptography/encryptDecrypt';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        const {
            executionObject={}
        } = functionObj
        const {
            variableName='', 
            variableUnavailable=false, 
            functionId='', functionParameters={},
            isRecurrent=[], recurrenceType=[],
            scheduleType=[], projectionHours=[],
            preciseDate=[], preciseDateTimezone=[],
            timedSchedule={}, taskDescription=[],
            taskName=[], intervalHours=[], iterationId=''
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableObjectCopy = variableObject;

        let isRecurrentParsed = await simpleScriptParser(
            isRecurrent,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let isRecurrentValue = isRecurrentParsed.value;
        variableObjectCopy = isRecurrentParsed.variableObject;

        let recurrenceTypeParsed = await simpleScriptParser(
            recurrenceType,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let recurrenceTypeValue = recurrenceTypeParsed.value;
        variableObjectCopy = recurrenceTypeParsed.variableObject;

        let scheduleTypeParsed = await simpleScriptParser(
            scheduleType,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let scheduleTypeValue = scheduleTypeParsed.value;
        variableObjectCopy = scheduleTypeParsed.variableObject;

        let projectionHoursParsed = await simpleScriptParser(
            projectionHours,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let projectionHoursValue = projectionHoursParsed.value;
        variableObjectCopy = projectionHoursParsed.variableObject;

        let intervalHoursParsed = await simpleScriptParser(
            intervalHours,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let intervalHoursValue = intervalHoursParsed.value;
        variableObjectCopy = intervalHoursParsed.variableObject;

        let preciseDateParsed = await simpleScriptParser(
            preciseDate,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let preciseDateValue = preciseDateParsed.value;
        variableObjectCopy = preciseDateParsed.variableObject;

        let preciseDateTimezoneParsed = await simpleScriptParser(
            preciseDateTimezone,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let preciseDateTimezoneValue = preciseDateTimezoneParsed.value;
        variableObjectCopy = preciseDateTimezoneParsed.variableObject;

        let taskDescriptionParsed = await simpleScriptParser(
            taskDescription,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let taskDescriptionValue = taskDescriptionParsed.value;
        variableObjectCopy = taskDescriptionParsed.variableObject;

        let timedScheduleParsed = await resolveExpression(
            timedSchedule,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let timedScheduleValue = timedScheduleParsed.value;
        variableObjectCopy = timedScheduleParsed.variableObject;

        let taskNameParsed = await simpleScriptParser(
            taskName,
            variableObjectCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let taskNameValue = taskNameParsed.value;
        variableObjectCopy = taskNameParsed.variableObject;

        let parsedFunctionParameters = {}
        if(Object.keys(functionParameters).length > 0){
            for (let key in functionParameters){
                let parameterExpression = functionParameters[key];
                let parsedParam = await resolveExpression(
                    parameterExpression,
                    variableObjectCopy,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                parsedFunctionParameters[key] = parsedParam.value;
            }
        }

        let scheduledTaskObject = {
            taskName : typeof taskNameValue === 'string' ? taskNameValue : '',
            taskDescription : typeof taskDescriptionValue === 'string' ? taskDescriptionValue : '',
            isRecurrent : isRecurrentValue ? true : false,
            recurrenceType : ['timed', 'always', 'interval'].includes(recurrenceTypeValue) ? 
            recurrenceTypeValue : 'interval',
            scheduleType : ['projection', 'preciseDate'].includes(scheduleTypeValue) ? 
            scheduleTypeValue : 'projection',
            projectionHours : typeof projectionHoursValue === 'number' ? projectionHoursValue : 0,
            preciseDate : preciseDateValue,
            preciseDateTimezone : preciseDateTimezoneValue && typeof preciseDateTimezoneValue === 'string' ? 
            preciseDateTimezoneValue : Intl.DateTimeFormat().resolvedOptions().timeZone,
            timedSchedule : Array.isArray(timedScheduleValue) ? timedScheduleValue : [],
            functionId,
            functionParameters : parsedFunctionParameters,
            functionGenerated : true,
            intervalHours : typeof intervalHoursValue === 'number' ? intervalHoursValue : 0,
            environment : appEnvironment.toLowerCase() === 'appbuilder' ? 'development' : appEnvironment
        }

        let encryptedCompanyId = encryptDecrypt(
            JSON.stringify({
                companyId,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let request = await postRequest(
            `${config.devServerProxy}/api/scheduledtask/create`,
            'POST',
            {
                scheduledTaskObject,
                encryptedCompanyId
            }
        );

        if(request.error || request.hasError){
            variableObjectCopy[variableName] = {
                error : {
                    message : request['errorMessage']
                }
            };
        }else{
            variableObjectCopy[variableName] = request;
        }

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObjectCopy,
            hasError : request.error || request.hasError ? true : false,
            errorPayload : request.errorPayload ? request.errorPayload : ''
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/createScheduledTaskParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}