import React, {Component} from 'react';
import {
    setSubscriptionAuthReducer, appPasswordValidation
} from '../../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { isEqual } from 'lodash';
import validator from 'validator';
import moment from 'moment';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';
import config from '../../../config/config';

class AppPasswordValidation extends Component{
    componentDidUpdate(prevProps){
        const {
            passwordValidationLoading, passwordValidationError
        } = this.props;
        if(
            !passwordValidationLoading && 
            !passwordValidationError && 
            !isEqual(passwordValidationLoading, prevProps.passwordValidationLoading)
        ){
            this.handlePostPasswordValidation();
        }
    }

    handlePostPasswordValidation = () => {
        const {
            assetId='', environment='',
            assetOwnerCompanyId='',
            setSubscriptionAuthReducer
        } = this.props;

        let encryptedData = encryptDecrypt(
            JSON.stringify({
                expirationTimestamp : moment().add(24, 'hours').unix() * 1000,
                payload : {
                    appId : assetId,
                    environment,
                    assetOwnerCompanyId
                }
            }),
            true
        );

        if(encryptedData && setSubscriptionAuthReducer){
            sessionStorage.setItem('x-java', encryptedData);
            setSubscriptionAuthReducer({
                loginMode : false,
                subscriberData : {
                    isAuthenticated : true
                }
            });
        }
    }

    handleLogin = () => {
        const {
            password='', appPasswordValidation,
            assetId='', destination='', assetOwnerCompanyId='',
            environment=''
        } = this.props;
        if(appPasswordValidation){
            let encryptedData = encryptDecrypt(
                JSON.stringify({
                    payload : {
                        password,
                        appId : assetId,
                        destination,
                        assetOwnerCompanyId,
                        environment
                    },
                    expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                }),
                true
            );
            appPasswordValidation({encryptedData});
            this.setState({
                loginAttempted : true
            });
        }
    }

    handleKeyDown = (e) => {
        if(e && (e.key === 'Enter')){
            this.handleLogin();
        }
    }

    handleInput = (field, value) => {
        const {
            setSubscriptionAuthReducer
        } = this.props;

        if(setSubscriptionAuthReducer){
            setSubscriptionAuthReducer({
                [field] : value
            });
        }
    }

    render(){
        const {
            password='', displayMode='',
            passwordValidationErrorMessage='', passwordValidationError=false,
            subscriberLoginLoading=false, passwordValidationLoading=false,
        } = this.props;
        let isLoading = passwordValidationLoading;
        let mobileMode = ['tablet', 'mobile'].includes(displayMode);
        return (
            <div
                className='bg-white p-3 shadow-sm'
                style={{
                    width : mobileMode ? '100%' : '50%'
                }}
            >   
                {
                    passwordValidationError && 
                    <div
                        className='alert alert-danger mb-3'
                    >
                        {
                            passwordValidationErrorMessage ?
                            passwordValidationErrorMessage
                            :
                            'Password validation failed.'
                        }
                    </div>
                }
                <div 
                    className='text-center mb-3 text-dark'
                    style={{
                        fontSize : '18px',
                        fontWeight : 'bold'
                    }}
                >
                    Enter password
                </div>
                <input 
                    className='form-control mb-1'
                    type='password'
                    placeholder='Password (Required)'
                    value={password}
                    onChange={(e) => this.handleInput('password', e.target.value)}
                    onKeyPress={(e) => this.handleKeyDown(e)}
                />
                <div
                    className='d-flex align-items-center justify-content-center'
                >
                    <button
                        className='btn btn-block btn-dark'
                        disabled={!password || isLoading}
                        onClick={() => this.handleLogin()}
                    >
                        {
                            subscriberLoginLoading ?
                            'Validating...'
                            :
                            'Validate password'
                        }
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user : state.SubscriptionAuth.subscriberData,
        passwordValidationLoading: state.SubscriptionAuth.passwordValidationLoading,
        passwordValidationError : state.SubscriptionAuth.passwordValidationError,
        passwordValidationErrorMessage : state.SubscriptionAuth.passwordValidationErrorMessage,
        password : state.SubscriptionAuth.password,
    }
}

export default connect(mapStateToProps, {
    setSubscriptionAuthReducer, appPasswordValidation
})(withRouter(AppPasswordValidation));