import getExternalFunction from "./simpleScript/utils/getExternalFunction";
import resolveExpression from "./simpleScript/expressionParser/resolveExpression";
import functionParser from "./functionParser";
import functionResponseObjectExtractor from "./general/functionResponseObjectExtractor";
import encryptDecrypt from "../cryptography/encryptDecrypt";
import moment from 'moment';

export default async function(functionParseParams={}){
    try{
        let {
            functionId='',
            functionParameters={},
            setStateFunction,
            companyId='',
            user={},
            userAgent={},
            app_data={},
            functionMap={},
            otherParameters={}
        } = functionParseParams;

        let functionData;
        if(functionMap[functionId]){
            functionData = functionMap[functionId];
        }else{
            let encryptedCompanyId=''
            if(functionId){
                if(companyId && typeof companyId === 'string'){
                    encryptedCompanyId = encryptDecrypt(
                        JSON.stringify({
                            companyId,
                            expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                        }),
                        true
                    )
                }
                if(
                    functionId &&
                    encryptedCompanyId
                ){
                    functionData = await getExternalFunction({
                        encryptedCompanyId,
                        functionId,
                        prod : true
                    });
                }
            }
        }

        if(
            !functionData ||
            (
                functionData && 
                (
                    functionData['error'] ||
                    Object.keys(functionData).length === 0
                )
            )
        ){
            return {
                error : {
                    message : 'Function was not found.'
                }
            }
        }
        
        let {event} = otherParameters;
        let parsedParams = {...otherParameters};
        parsedParams['App_Data'] = app_data;
        parsedParams['ENV'] = (app_data['appSettings'] && app_data['appSettings']['ENV']) || {};
        parsedParams['user'] = user;
        parsedParams['userAgent'] = userAgent;
        parsedParams['companyId'] = companyId;
        parsedParams['eventTargetValue'] = ![null, undefined].includes(otherParameters['eventTargetValue']) ? 
        otherParameters['eventTargetValue'] 
        :
        (event && event.target && event.target.value) || '';

        let {parameters=[]} = functionData;
        for (let i = 0; i < parameters.length; i++){
            let parameterObj = parameters[i];
            const {
                systemGenerated=false, parameterName=''
            } = parameterObj;
            if(
                !systemGenerated && 
                functionParameters[parameterName] && 
                !parsedParams[parameterName]
            ){
                let paramScriptObj = functionParameters[parameterName];
                let parsedParamRes = await resolveExpression(
                    paramScriptObj,
                    {},
                    parsedParams,
                    functionMap,
                    {}
                );
                parsedParams[parameterName] = parsedParamRes.value;
            }
        }

        let functionResponse = await functionParser(
            functionData,
            parsedParams,
            setStateFunction,
            functionMap,
            {}
        );
        let extractedResponseObj = functionResponseObjectExtractor(functionResponse);

        functionId = null;
        functionParameters = null;
        user = null;
        userAgent = null;
        app_data = null;
        functionMap = null;
        otherParameters = null;
        functionParseParams = null;
        functionData = null;
        
        parsedParams = null;
        parameters= null;
        functionResponse = null;
        return extractedResponseObj.hasError || 
        !extractedResponseObj.hasReturnStatement ? 
        undefined : extractedResponseObj.returnStatement;
    }catch(e){
        console.log('Failed to execute function', e);
        return {
            error : {
                message : 'Failed to execute function',
                errorPayload : e.stack
            }
        }
    }
}