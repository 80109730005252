import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;

        let resolvedEmailIds = await resolveExpression(
            executionObject['emailList'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let emailList = resolvedEmailIds.value;
        variableCopy = resolvedEmailIds.variableObject;

        let resolvedEmail = await resolveExpression(
            executionObject['email'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let email = resolvedEmail.value;
        variableCopy = resolvedEmail.variableObject;

        let resolvedFirstName = await resolveExpression(
            executionObject['firstName'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let firstName = resolvedFirstName.value;
        variableCopy = resolvedFirstName.variableObject;

        let resolvedLastName = await resolveExpression(
            executionObject['lastName'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let lastName = resolvedLastName.value;
        variableCopy = resolvedLastName.variableObject;

        let resolvedCompanyName = await resolveExpression(
            executionObject['companyName'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let companyName = resolvedCompanyName.value;
        variableCopy = resolvedCompanyName.variableObject;

        let resolvedCompanyId = await resolveExpression(
            executionObject['companyId'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let customerCompanyId = resolvedCompanyId.value;
        variableCopy = resolvedCompanyId.variableObject;

        let resolvedPaymentOriginId = await resolveExpression(
            executionObject['paymentOriginId'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let paymentOriginId = resolvedPaymentOriginId.value;
        variableCopy = resolvedPaymentOriginId.variableObject;

        let resolvedPaymentOrigin = await resolveExpression(
            executionObject['paymentOrigin'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let paymentOrigin = resolvedPaymentOrigin.value;
        variableCopy = resolvedPaymentOrigin.variableObject;

        let resolvedInhouzPaymentId = await resolveExpression(
            executionObject['inhouzPaymentId'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let inhouzPaymentId = resolvedInhouzPaymentId.value;
        variableCopy = resolvedInhouzPaymentId.variableObject;

        let resolvedProductId = await resolveExpression(
            executionObject['productId'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let productId = resolvedProductId.value;
        variableCopy = resolvedProductId.variableObject;

        let resolvedStatus = await resolveExpression(
            executionObject['status'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let status = resolvedStatus.value;
        variableCopy = resolvedStatus.variableObject;

        let resolvedActive = await resolveExpression(
            executionObject['active'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let active = resolvedActive.value;
        variableCopy = resolvedActive.variableObject;

        let resolvedSkip = await resolveExpression(
            executionObject['skip'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let skip = resolvedSkip.value;
        variableCopy = resolvedSkip.variableObject;

        let resolvedLimit = await resolveExpression(
            executionObject['limit'],
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let limit = resolvedLimit.value;
        variableCopy = resolvedLimit.variableObject;

        let filter = {};
        if(
            Array.isArray(emailList) && 
            emailList.length > 0
        ){
            filter['emailList'] = emailList;
        }

        if(email){
            filter['email'] = email;
        }
        if(firstName){
            filter['firstName'] = firstName;
        }
        if(lastName){
            filter['lastName'] = lastName;
        }
        if(companyName){
            filter['companyName'] = companyName;
        }
        if(customerCompanyId){
            filter['companyId'] = customerCompanyId;
        }
        if(paymentOrigin){
            filter['paymentOrigin'] = typeof paymentOrigin === 'string' ?
            paymentOrigin.toLowerCase() : paymentOrigin;
        }
        if(paymentOriginId){
            filter['paymentOriginId'] = paymentOriginId;
        }
        if(inhouzPaymentId){
            filter['inhouzPaymentId'] = inhouzPaymentId;
        }
        if(productId){
            filter['productId'] = productId;
        }
        if(status){
            filter['status'] = status;
        }
        if(typeof active === 'boolean'){
            filter['active'] = active;
        }

        let payload = {
            filter,
            assetOwnerCompanyId : companyId,
            environment : appEnvironment,
            sort : {
                createTimestamp : 1
            },
            functionType : 'paymentSubscriberSearch'
        }
        if(typeof skip === 'number'){
            payload['skip'] = skip;
        }
        if(typeof limit === 'number'){
            payload['limit'] = limit;
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/inhouzpay/payment/subscription/paymentsubscriberquery`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(response['error']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed while searching for subscribers.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        variableCopy[variableName] = response['results'] || [];
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/paymentSubscriberSearch catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}