import React, {Component} from 'react';
import {
    setSubscriptionAuthReducer, subscriptionLogin,
    subscriptionPasswordChangeRequest, subscriptionGetUser
} from '../../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { isEqual } from 'lodash';
import validator from 'validator';
import moment from 'moment';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';

class SubscriptionLogin extends Component{
    state = {
        loginAttempted : false,
        invalidEmailError : false
    }
    errorInterval;

    componentDidUpdate(prevProps){
        const {
            subscriberLoginLoading, subscriberLoginError,
            twoFactorRequired
        } = this.props;
        
        if(
            this.state.loginAttempted &&
            !subscriberLoginLoading && 
            !subscriberLoginError && 
            !twoFactorRequired &&
            !isEqual(subscriberLoginLoading, prevProps.subscriberLoginLoading)
        ){
            this.handlePostLogin();
        }
    }   

    handlePostLogin = () => {
        const {
            subscriptionGetUser, setSubscriptionAuthReducer
        } = this.props;

        if(subscriptionGetUser){
            subscriptionGetUser();
            this.setState({
                loginAttempted : false
            });
            if(setSubscriptionAuthReducer){
                setTimeout(() => {
                    setSubscriptionAuthReducer({
                        twoFactorRequired : false,
                        passwordChangeInProgress : false,
                        validatorId : '',
                        twoFactorCode : '',
                        resetPassword : '',
                        newPassword : '',
                        resetPasswordEmail : '',
                        email : '',
                        password : '',
                    });
                }, 2000);
            }
        }
    }

    handleKeyDown = (e) => {
        if(e && (e.key === 'Enter')){
            this.handleLogin();
        }
    }

    handleLogin = () => {
        const {
            email='', password='', subscriptionLogin,
            assetId='', destination='', assetOwnerCompanyId='',
            companyId='', environment=''
        } = this.props;
        if(subscriptionLogin){
            let encryptedData = encryptDecrypt(
                JSON.stringify({
                    data : {
                        subscriberEmail : email,
                        subscriberPassword : password,
                        assetId,
                        destination,
                        assetOwnerCompanyId,
                        companyId,
                        environment
                    },
                    expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                }),
                true
            );
            subscriptionLogin({encryptedData});
            this.setState({
                loginAttempted : true
            });
        }
    }

    handlePasswordChangeRequest = () => {
        const {
            email='', subscriptionPasswordChangeRequest,
            assetId='', destination='', assetOwnerCompanyId='',
            companyId=''
        } = this.props;
        if(subscriptionPasswordChangeRequest){
            if(validator.isEmail(email || '')){
                let encryptedData = encryptDecrypt(
                    JSON.stringify({
                        data : {
                            subscriberEmail : email,
                            assetId,
                            destination,
                            assetOwnerCompanyId,
                            companyId
                        },
                        expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                    }),
                    true
                );
                subscriptionPasswordChangeRequest({encryptedData});
            }else{
                this.setState({
                    invalidEmailError : true
                }, () => {
                    if(this.errorInterval){
                        clearTimeout(this.errorInterval);
                    }
                    this.errorInterval = setTimeout(() => {
                        this.setState({
                            invalidEmailError : false
                        });
                    }, 4000)
                });
            }
        }
    }

    handleInput = (field, value) => {
        const {
            setSubscriptionAuthReducer
        } = this.props;

        if(setSubscriptionAuthReducer){
            setSubscriptionAuthReducer({
                [field] : value
            });
        }
    }

    render(){
        const {
            email='', password='',
            subscriberDataErrorMessage='', subscriberDataError=false,
            subscriberLoginError=false, subscriberLoginErrorMessage='',
            subscriberLoginLoading=false, subcriberDataLoading=false,
            forgotPasswordLoading=false, forgotPasswordError=false,
            forgotPasswordErrorMessage='', assetId='', appMap={}
        } = this.props;
        let isLoading = subscriberLoginLoading || subcriberDataLoading || forgotPasswordLoading;
        let appData = appMap[assetId] || {};
        const {appLogoImageUrl=''} = appData;
        return (
            <div
                style={{
                    width : '100%'
                }}
            >   
                {
                    forgotPasswordError && 
                    <div
                        className='alert alert-danger mb-3'
                    >
                        {
                            forgotPasswordErrorMessage ?
                            forgotPasswordErrorMessage
                            :
                            'Password change request failed.'
                        }
                    </div>
                }
                {
                    subscriberDataError && 
                    email && 
                    this.state.loginAttempted &&
                    <div
                        className='alert alert-danger mb-3'
                    >
                        {
                            subscriberDataErrorMessage ?
                            subscriberDataErrorMessage
                            :
                            'Failed to get subscribers data.'
                        }
                    </div>
                }
                {
                    subscriberLoginError && 
                    <div
                        className='alert alert-danger mb-3'
                    >
                        {
                            subscriberLoginErrorMessage ?
                            subscriberLoginErrorMessage
                            :
                            'Authentication failed.'
                        }
                    </div>
                }
                {
                    this.state.invalidEmailError && 
                    <div
                        className='alert alert-danger my-1'
                    >
                        Please enter a valid email.
                    </div>
                }
                {
                    appLogoImageUrl && 
                    <div
                        className='d-flex justify-content-center mb-1'
                    >
                        <img 
                            src={appLogoImageUrl}
                            style={{
                                height : '50px'
                            }}
                        />
                    </div>
                }
                <div 
                    className='text-center mb-3 text-dark'
                    style={{
                        fontSize : '18px',
                        fontWeight : 'bold'
                    }}
                >
                    Sign In
                </div>
                <input 
                    className='form-control mb-3'
                    type='text'
                    placeholder='Email (Required)'
                    value={email}
                    onChange={(e) => this.handleInput('email', e.target.value)}
                />
                <input 
                    className='form-control mb-1'
                    type='password'
                    placeholder='Password (Required)'
                    value={password}
                    disabled={!validator.isEmail(email)}
                    onChange={(e) => this.handleInput('password', e.target.value)}
                    onKeyPress={(e) => this.handleKeyDown(e)}
                />
                <button
                    className='btn btn-link mb-3 p-0'
                    style={{
                        fontSize : '14px'
                    }}
                    // disabled={!validator.isEmail(email)}
                    title='Begin the password reset process. A valid email is required.'
                    onClick={() => this.handlePasswordChangeRequest()}
                >
                    {
                        forgotPasswordLoading ?
                        'Password reset processing...'
                        :
                        'Forgot your password?'
                    }
                </button>
                <div
                    className='d-flex align-items-center justify-content-center'
                >
                    <button
                        className='btn btn-block btn-dark'
                        disabled={!password || !validator.isEmail(email) || isLoading}
                        onClick={() => this.handleLogin()}
                    >
                        {
                            subscriberLoginLoading ?
                            'Signin in...'
                            :
                            subcriberDataLoading ?
                            'Getting your information...'
                            :
                            'Sign in'
                        }
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user : state.SubscriptionAuth.subscriberData,
        subcriberDataLoading: state.SubscriptionAuth.subcriberDataLoading,
        subscriberDataError : state.SubscriptionAuth.subscriberDataError,
        subscriberDataErrorMessage : state.SubscriptionAuth.subscriberDataErrorMessage,
        subscriberLoginLoading : state.SubscriptionAuth.subscriberLoginLoading,
        subscriberLoginError: state.SubscriptionAuth.subscriberLoginError,
        subscriberLoginErrorMessage: state.SubscriptionAuth.subscriberLoginErrorMessage,
        email : state.SubscriptionAuth.email,
        password : state.SubscriptionAuth.password,
        forgotPasswordLoading : state.SubscriptionAuth.forgotPasswordLoading,
        forgotPasswordError : state.SubscriptionAuth.forgotPasswordError,
        forgotPasswordErrorMessage : state.SubscriptionAuth.forgotPasswordErrorMessage,
        twoFactorRequired: state.SubscriptionAuth.twoFactorRequired,
        appMap : state.AppViewer.appMap
    }
}

export default connect(mapStateToProps, {
    setSubscriptionAuthReducer, subscriptionLogin,
    subscriptionPasswordChangeRequest, subscriptionGetUser
})(withRouter(SubscriptionLogin));