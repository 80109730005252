import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import sizeof from 'object-sizeof';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', user={}
        } = parameterObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        if(parsedParameters.error){
            variableObject[variableName] = {
                error : {
                    message : 'Failed to parse function parameters.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        let payload = {
            companyId,
            fileName : resolvedParams['fileName'] || '',
            fileKey : resolvedParams['fileKey'] || '',
            fileType : resolvedParams['fileType'] || '',
            projectId : resolvedParams['projectId'] || '',
            fileStorageServiceId : resolvedParams['fileStorageServiceId'] || '',
            fileSize : sizeof(resolvedParams['base64'] || ''),
            fileOrigin : 'inhouz function',
            user,
            queryKey : `${Math.random()}${Math.random()}${Math.random()}${Math.random()}`
        }

        if(
            !payload['fileStorageServiceId'] ||
            typeof payload['fileStorageServiceId'] !== 'string'
        ){
            variableObject[variableName] = {
                error : {
                    message : 'File storage service ID is required.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'File storage service ID is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !resolvedParams['base64'] ||
            typeof resolvedParams['base64'] !== 'string'
        ){
            variableObject[variableName] = {
                error : {
                    message : 'File base64 text is missing.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'File base64 text is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/multimedia/upload`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload,
                    base64 : resolvedParams['base64']
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/fileStorageUploadParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}