import React, {Component} from 'react';
import {
    setSubscriptionAuthReducer, subscriptionLogin,
    subscriptionValidateTwoFactor, subscriptionResendTwoFactor,
    subscriptionGetUser
} from '../../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';

class SubscriptionTwoFactor extends Component{
    state = {
        twoFactorSuccess : false,
        regenerateTokenSuccess : false
    }

    componentDidUpdate(prevProps){
        const {
            validateTwoFactorLoading, validateTwoFactorError,
            regenerateTwoFactorLoading, regenerateTwoFactorError
        } = this.props;

        if(
            !validateTwoFactorLoading && 
            !validateTwoFactorError && 
            !isEqual(validateTwoFactorLoading, prevProps.validateTwoFactorLoading)
        ){
            this.handlePostAction('twoFactorSuccess');
        }

        if(
            !regenerateTwoFactorLoading && 
            !regenerateTwoFactorError && 
            !isEqual(regenerateTwoFactorLoading, prevProps.regenerateTwoFactorLoading)
        ){
            this.handlePostAction('regenerateTokenSuccess');
        }
    }

    handlePostAction = (field) => {
        this.setState({
            [field] : true
        }, () => {
            setTimeout(() => {
                this.setState({
                    [field] : false
                });
                if(field === 'twoFactorSuccess'){
                    const {
                        subscriptionGetUser,
                        setSubscriptionAuthReducer
                    } = this.props;
                    if(subscriptionGetUser){
                        subscriptionGetUser();
                    }
                    if(setSubscriptionAuthReducer){
                        setTimeout(() => {
                            setSubscriptionAuthReducer({
                                twoFactorRequired : false,
                                passwordChangeInProgress : false,
                                validatorId : '',
                                twoFactorCode : '',
                                resetPassword : '',
                                newPassword : '',
                                resetPasswordEmail : '',
                                email : '',
                                password : '',
                            });
                        }, 2000);
                    }
                }
            }, 2000)
        });
    }

    handleInput = (field, value) => {
        const {
            setSubscriptionAuthReducer
        } = this.props;

        if(setSubscriptionAuthReducer){
            setSubscriptionAuthReducer({
                [field] : value
            });
        }
    }

    validateTwoFactor = () => {
        const {
            validatorId='', twoFactorCode='', 
            subscriptionValidateTwoFactor
        } = this.props;
        if(subscriptionValidateTwoFactor){
            let encryptedData = encryptDecrypt(
                JSON.stringify({
                    data : {
                        id : validatorId,
                        token : twoFactorCode
                    },
                    expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                }),
                true
            );
            subscriptionValidateTwoFactor({encryptedData});
        }
    }

    resendTwoFactor = () => {
        const {
            validatorId='',
            subscriptionResendTwoFactor
        } = this.props;
        if(subscriptionResendTwoFactor){
            let encryptedData = encryptDecrypt(
                JSON.stringify({
                    data : {
                        id : validatorId
                    },
                    expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                }),
                true
            );
            subscriptionResendTwoFactor({encryptedData});
        }
    }

    render(){
        const {
            twoFactorSuccess=false, regenerateTokenSuccess=false
        } = this.state;
        const {
            twoFactorCode='', validateTwoFactorError=false,
            validateTwoFactorErrorMessage='', regenerateTwoFactorLoading=false,
            validateTwoFactorLoading=false, regenerateTwoFactorError=false,
            regenerateTwoFactorErrorMessage='', subcriberDataLoading=false,
            subscriberDataError='', subscriberDataErrorMessage='', appMap={},
            assetId=''
        } = this.props;
        let appData = appMap[assetId] || {};
        const {appLogoImageUrl=''} = appData;
        return (
            <div
                style={{
                    width : '100%'
                }}
            >
                {
                    subscriberDataError && 
                    <div
                        className='alert alert-danger my-1'
                    >
                        {
                            subscriberDataErrorMessage ? 
                            `${subscriberDataErrorMessage}`
                            :
                            'Failed to get subscribers data.'
                        }
                    </div>
                }
                {
                    validateTwoFactorError && 
                    <div
                        className='alert alert-danger my-1'
                    >
                        {
                            validateTwoFactorErrorMessage ? 
                            `${validateTwoFactorErrorMessage}`
                            :
                            'Two factor failed.'
                        }
                    </div>
                }
                {
                    regenerateTwoFactorError && 
                    <div
                        className='alert alert-danger my-1'
                    >
                        {
                            regenerateTwoFactorErrorMessage ? 
                            `${regenerateTwoFactorErrorMessage}`
                            :
                            'Failed to refresh two factor token'
                        }
                    </div>
                }
                {
                    (
                        twoFactorSuccess ||
                        regenerateTokenSuccess
                    ) && 
                    <div
                        className='alert alert-success my-1'
                    >
                        Success
                    </div>
                }
                {
                    appLogoImageUrl && 
                    <div
                        className='d-flex justify-content-center mb-1'
                    >
                        <img 
                            src={appLogoImageUrl}
                            style={{
                                height : '50px'
                            }}
                        />
                    </div>
                }
                <div
                    className='text-dark mb-2'
                    style={{
                        fontSize : '18px',
                        fontWeight : 'bold'
                    }}
                >
                    Enter the two factor token sent via email
                </div>
                <input 
                    value={twoFactorCode}
                    className='form-control'
                    onChange={(e) => this.handleInput('twoFactorCode', e.target.value)}
                /> 
                <div
                    className='d-flex align-items-center justify-content-between my-1'
                >
                    <button
                            className='btn btn-link'
                            disabled={validateTwoFactorLoading || regenerateTwoFactorLoading || subcriberDataLoading}
                            onClick={() => this.resendTwoFactor()}
                        >
                            {
                                validateTwoFactorLoading ?
                                ''
                                :
                                regenerateTwoFactorLoading ?
                                'Sending token...'
                                :
                                'Resend Token'
                            }
                        </button>
                        <button
                            className='btn btn-dark'
                            disabled={!twoFactorCode || regenerateTwoFactorLoading || validateTwoFactorLoading || subcriberDataLoading}
                            onClick={() => this.validateTwoFactor()}
                        >
                            {
                                validateTwoFactorLoading ?
                                'Validating...'
                                :
                                subcriberDataLoading ?
                                'Getting your information...'
                                :
                                'Validate token'
                            }
                        </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user : state.SubscriptionAuth.subscriberData,
        subcriberDataLoading: state.SubscriptionAuth.subcriberDataLoading,
        subscriberDataError : state.SubscriptionAuth.subscriberDataError,
        subscriberDataErrorMessage : state.SubscriptionAuth.subscriberDataErrorMessage,
        validateTwoFactorLoading: state.SubscriptionAuth.validateTwoFactorLoading,
        validateTwoFactorError: state.SubscriptionAuth.validateTwoFactorError,
        validateTwoFactorErrorMessage: state.SubscriptionAuth.validateTwoFactorErrorMessage,
        regenerateTwoFactorLoading: state.SubscriptionAuth.regenerateTwoFactorLoading,
        regenerateTwoFactorError: state.SubscriptionAuth.regenerateTwoFactorError,
        regenerateTwoFactorErrorMessage: state.SubscriptionAuth.regenerateTwoFactorErrorMessage,
        email : state.SubscriptionAuth.email,
        password : state.SubscriptionAuth.password,
        validatorId : state.SubscriptionAuth.validatorId,
        twoFactorCode : state.SubscriptionAuth.twoFactorCode,
        appMap : state.AppViewer.appMap
    }
}

export default connect(mapStateToProps, {
    setSubscriptionAuthReducer, subscriptionLogin,
    subscriptionValidateTwoFactor, subscriptionResendTwoFactor,
    subscriptionGetUser
})(withRouter(SubscriptionTwoFactor));