import NodeRSA from 'node-rsa';

let devServerProxy = '';
export default {
    devServerProxy,
    cardsWithFontAwesomeIcons : ['amex', 'discover', 'visa', 'diners_club', 'mastercard', 'jcb'],
    cardsWithIconsMap : {
        amex : 'fab fa-cc-amex',
        discover : 'fab fa-cc-discover',
        visa : 'fab fa-cc-visa',
        diners_club : 'fab fa-cc-diners-club',
        mastercard : 'fab fa-cc-mastercard',
        jcb : 'fab fa-cc-jcb',
        creditcard : 'far fa-credit-card'
    },
    redisDB : process.env.RAZZLE_REDIS_DB,
    singlePageAppTypes : ['emailFunction', 'webComponent'],
    nonLifecycleAppTypes : ['emailFunction', 'pdfFunction', 'inhouzSign'],
    birdViewAppTypes : ['pdfFunction', 'inhouzSign', 'presentation'],
    NodeRsaClientPublicKey : new NodeRSA(process.env.RAZZLE_CLIENT_PUBLIC_KEY, {environment : 'browser'}),
    mediaProxyUrl : process.env.RAZZLE_MEDIA_PROXY_URL || '',
    webPushPublicKey : process.env.RAZZLE_APP_WEB_PUSH_PUBLIC_KEY,
    windowElementTypes : [
        'PdfViewer', 'SignatureViewer', 'StripePaymentElementViewer'
    ]
}