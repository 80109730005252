import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import dotNotationMapper from '../../data/dotNotationMapper';
import validator from 'validator';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        if(parsedParameters.error){
            variableObject[variableName] = {
                error : {
                    message : 'Failed to parse function parameters.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        if(
            !resolvedParams['templateId'] ||
            typeof resolvedParams['templateId'] !== 'string'
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'A valid template ID is required.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'A valid template ID is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let recipientList = resolvedParams['recipientList'];
        if(
            !Array.isArray(recipientList) ||
            (
                Array.isArray(recipientList) && 
                recipientList.length === 0
            )
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'A valid recipient list is required.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'A valid recipient list is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let cleanRecipientList = [];
        for (let i = 0; i < recipientList.length; i++){
            let recipientObj = recipientList[i];
            let newRecipientObject = {
                firstName : dotNotationMapper(recipientObj, resolvedParams['recipientFirstNameField']),
                lastName : dotNotationMapper(recipientObj, resolvedParams['recipientLastNameField']),
                email : dotNotationMapper(recipientObj, resolvedParams['recipientEmailField']),
                roleName : dotNotationMapper(recipientObj, resolvedParams['recipientRoleNameField']),
                phoneNumber : dotNotationMapper(recipientObj, resolvedParams['recipientPhoneNumberField']),
                organizationName : dotNotationMapper(recipientObj, resolvedParams['recipientOrganizationNameField']),
                jobTitle : dotNotationMapper(recipientObj, resolvedParams['recipientJobTitleField']),
                emailSubject : dotNotationMapper(recipientObj, resolvedParams['recipientCustomEmailSubjectField']),
                emailMessage : dotNotationMapper(recipientObj, resolvedParams['recipientCustomEmailMessageField']),
                address : {
                    line1 : dotNotationMapper(recipientObj, resolvedParams['recipientAddressLineOneField']),
                    line2 : dotNotationMapper(recipientObj, resolvedParams['recipientAddressLineTwoField']),
                    city : dotNotationMapper(recipientObj, resolvedParams['recipientAddressCityField']),
                    state : dotNotationMapper(recipientObj, resolvedParams['recipientAddressStateField']),
                    country : dotNotationMapper(recipientObj, resolvedParams['recipientAddressCountryField']),
                    postalCode : dotNotationMapper(recipientObj, resolvedParams['recipientAddressPostalCodeField'])
                }
            }

            if(
                newRecipientObject['firstName'] && 
                newRecipientObject['lastName'] && 
                newRecipientObject['roleName'] &&
                validator.isEmail(newRecipientObject['email']) && 
                typeof newRecipientObject['firstName'] === 'string' && 
                typeof newRecipientObject['lastName'] === 'string' && 
                typeof newRecipientObject['roleName'] === 'string'
            ){
                cleanRecipientList.push(newRecipientObject);
            }
        }

        if(
            cleanRecipientList.length === 0
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'A valid recipient list is required. A recipient must have a firstName, lastName, email, and roleName.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'A valid recipient list is required. A recipient must have a firstName, lastName, email, and roleName.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let document = {
            environment : appEnvironment,
            documentName : resolvedParams['documentName'] || '',
            projectId : resolvedParams['projectId'] || '',
            emailSubject : resolvedParams['emailSubject'] || '',
            emailMessage : resolvedParams['emailMessage'] || '',
            draftFileStorageServiceId : resolvedParams['draftFileStorageServiceId'] || '',
            attachmentFileStorageServiceId : resolvedParams['attachmentFileStorageServiceId'] || '',
            completedDocumentFileStorageServiceId : resolvedParams['completedDocumentFileStorageServiceId'] || '',
            accessRestricted : resolvedParams['enableAccessRestrictions'] ? true : false,
            recipients : cleanRecipientList,
            collectPayment : resolvedParams['collectPayments'],
            useTemplatePaymentConfigurations : resolvedParams['useTemplatePaymentConfigurations'] || false
        }

        if(
            resolvedParams['collectPayments'] && 
            !resolvedParams['useTemplatePaymentConfigurations']
        ){
            let paymentDetails = {
                themeId : resolvedParams['paymentPageDesignId'] || '',
                payoutAccountId : resolvedParams['payoutAccountId'] || '',
                name : resolvedParams['paymentPageName'] || '',
                description : resolvedParams['paymentDescription'] || '',
                paymentLinkType : resolvedParams['paymentLinkType'] || '',
                defaultCurrency : resolvedParams['defaultCurrency'] || 'USD',
                collectBillingAddress : resolvedParams['collectBillingAddress'] || false,
                collectShippingAddress : resolvedParams['collectShippingAddress'] || false,
                collectTax : resolvedParams['collectTax'] || false,
                addressToTax : resolvedParams['addressToTax'] || '',
                invoiceNumber : resolvedParams['invoiceNumber'] || '',
                invoiceDueDate : resolvedParams['invoiceDueDate'] || 0,
                isSingleUse : resolvedParams['isSingleUse'] || false,
                emailMessage : resolvedParams['paymentEmailMessage'] || '',
                collectInvoiceNumber : resolvedParams['collectInvoiceNumber'] || false,
                collectPurchaseOrderNumber : resolvedParams['collectPurchaseOrderNumber'] || false,
                collectPhoneNumber : resolvedParams['collectPhoneNumber'] || false,
                collectFirstName : resolvedParams['collectFirstName'] || false,
                collectLastName : resolvedParams['collectLastName'] || false,
                collectCompanyName : resolvedParams['collectCompanyName'] || false,
                paymentAmount : resolvedParams['paymentAmount'] || 0,
                applySimpleInterest : resolvedParams['applySimpleInterest'] || false,
                simpleInterestPercentage : resolvedParams['simpleInterestPercentage'] || 0,
                simpleInterestDurationType : resolvedParams['simpleInterestDurationType'] || '',
                simpleInterestDurationCount : resolvedParams['simpleInterestDurationCount'] || 0,
                delayInitialPayment : resolvedParams['delayInitialPayment'] || false,
                initialPaymentDelayDayCount : resolvedParams['initialPaymentDelayDayCount'] || 0,
                paymentCategoryId : resolvedParams['paymentCategoryId'] || '',
                showTermsOfUse : resolvedParams['showPaymentTermsOfUse'] || false,
                termsOfUse : resolvedParams['paymentTermsOfUse'] || '',
                useExternalTermsOfUse : resolvedParams['useExternalPaymentTermsOfUse'] || false,
                termsOfUseUrl : resolvedParams['paymentTermsOfUseUrl'] || '',
                collectTermsOfUseAcknowledgement : resolvedParams['collectPaymentTermsOfUseAcknowledgement'] || false,
                useInhouzProducts : resolvedParams['useInhouzProducts'] || false,
                fixedScheduledPaymentCount : resolvedParams['fixedScheduledPaymentCount'] || 0,
                fixedScheduleDayInterval : resolvedParams['fixedScheduleDayInterval'] || 0,
                devWebhookUrl : resolvedParams['devPaymentWebhookUrl'] || '',
                testWebhookUrl : resolvedParams['testPaymentWebhookUrl'] || '',
                productionWebhookUrl : resolvedParams['productionPaymentWebhookUrl'] || '',
                styleConfiguration : styleConfiguration
            }

            if(
                !paymentDetails['payoutAccountId'] ||
                !paymentDetails['name'] ||
                (
                    !paymentDetails['paymentLinkType'] ||
                    ![
                        'fixed-schedule', 'flex-schedule', 
                        'one-time', 'recurring', 'split-payment',
                        'adjustable'
                    ].includes(paymentDetails['paymentLinkType'].toLowerCase())
                ) ||
                !paymentDetails['defaultCurrency'] ||
                (
                    [
                        'fixed-schedule', 'flex-schedule', 
                        'split-payment'
                    ].includes(paymentDetails['paymentLinkType'].toLowerCase()) &&
                    !paymentDetails['paymentAmount']
                )
            ){
                let errMsg = '';
                if(!paymentDetails['payoutAccountId']){
                    errMsg = 'A payout account ID is required';
                }else if(!paymentDetails['name']){
                    errMsg = 'A payment page name is required.';
                }else if(
                    !paymentDetails['paymentLinkType'] ||
                    ![
                        'fixed-schedule', 'flex-schedule', 
                        'one-time', 'recurring', 'split-payment'
                    ].includes(paymentDetails['paymentLinkType'].toLowerCase())
                ){
                    errMsg = 'A valid payment type is required.';
                }else if(!paymentDetails['defaultCurrency']){
                    errMsg = 'A valid currency is required.';
                }else if(
                    [
                        'fixed-schedule', 'flex-schedule', 
                        'split-payment'
                    ].includes(paymentDetails['paymentLinkType'].toLowerCase()) &&
                    !paymentDetails['paymentAmount']
                ){
                    errMsg = `A payment amount is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`
                }

                variableCopy[variableName] = {
                    error : {
                        message : errMsg
                    }
                }
                return {
                    updatedVariableObject : variableCopy,
                    hasError : true,
                    errorPayload : errMsg,
                    hasReturnStatement : false,
                    returnStatement : undefined
                }
            }

            if(paymentDetails['paymentLinkType'] === 'fixed-schedule'){
                if(
                    !paymentDetails['fixedScheduledPaymentCount'] ||
                    !paymentDetails['fixedScheduleDayInterval']
                ){
                    let errMsg = '';
                    if(!paymentDetails['fixedScheduledPaymentCount']){
                        errMsg = 'Fixed schedule payment count is required for fixed-schedule payments.'
                    }else if(!paymentDetails['fixedScheduleDayInterval']){
                        errMsg = 'A payment interval (days) is required for fixed-schedule payments.'
                    }

                    variableCopy[variableName] = {
                        error : {
                            message : errMsg
                        }
                    }
                    return {
                        updatedVariableObject : variableCopy,
                        hasError : true,
                        errorPayload : errMsg,
                        hasReturnStatement : false,
                        returnStatement : undefined
                    }
                }
            }

            if(
                [
                    'fixed-schedule',
                    'flex-schedule'
                ].includes(paymentDetails['paymentLinkType'])
            ){
                if(
                    paymentDetails['applySimpleInterest'] && 
                    (
                        !paymentDetails['simpleInterestPercentage'] ||
                        !paymentDetails['simpleInterestDurationType'] ||
                        !paymentDetails['simpleInterestDurationCount'] 
                    )
                ){
                    let errMsg = '';
                    if(!paymentDetails['simpleInterestPercentage']){
                        errMsg = 'A simple interest percentage is required when applying simple interest to payments.';
                    }else if(!paymentDetails['simpleInterestDurationType']){
                        errMsg = 'A simple interest duration type (month, year) is required when applying simple interest to payments.';
                    }else if(!paymentDetails['simpleInterestDurationCount']){
                        errMsg = 'A simple interest duration count is required when applying simple interest to payments.'
                    }

                    variableCopy[variableName] = {
                        error : {
                            message : errMsg
                        }
                    }
                    return {
                        updatedVariableObject : variableCopy,
                        hasError : true,
                        errorPayload : errMsg,
                        hasReturnStatement : false,
                        returnStatement : undefined
                    }
                }
            }
    
            //resolve payment methods
            let paymentMethods = [];
            if(
                resolvedParams['paymentMethod1'] && 
                typeof resolvedParams['paymentMethod1'] === 'string' && 
                paymentOptions.includes(resolvedParams['paymentMethod1'].toLowerCase())
            ){
                paymentMethods.push(resolvedParams['paymentMethod1'].toLowerCase());
            }
            if(
                resolvedParams['paymentMethod2'] && 
                typeof resolvedParams['paymentMethod2'] === 'string' && 
                paymentOptions.includes(resolvedParams['paymentMethod2'].toLowerCase())
            ){
                paymentMethods.push(resolvedParams['paymentMethod2'].toLowerCase());
            }
            if(
                resolvedParams['paymentMethod3'] && 
                typeof resolvedParams['paymentMethod3'] === 'string' && 
                paymentOptions.includes(resolvedParams['paymentMethod3'].toLowerCase())
            ){
                paymentMethods.push(resolvedParams['paymentMethod3'].toLowerCase());
            }
    
            paymentDetails['paymentMethods'] = paymentMethods;

            if(
                paymentMethods.length === 0
            ){
                variableCopy[variableName] = {
                    error : {
                        message : 'A minimum of one payment method is required.'
                    }
                }
                return {
                    updatedVariableObject : variableCopy,
                    hasError : true,
                    errorPayload : 'A minimum of one payment method is required.',
                    hasReturnStatement : false,
                    returnStatement : undefined
                }
            }
    
            //resolve product list
            let products = [];
            if(
                [
                    'one-time', 'recurring'
                ].includes(resolvedParams['paymentLinkType']) &&
                Array.isArray(resolvedParams['productList']) && 
                resolvedParams['productList'].length > 0
            ){
                for (let i = 0; i < resolvedParams['productList'].length; i++){
                    let productObj = resolvedParams['productList'][i];
                    let product = {};
                    if(
                        resolvedParams['productIdField']
                    ){
                        let productId = dotNotationMapper(productObj, resolvedParams['productIdField']);
                        if(
                            productId && 
                            typeof productId === 'string'
                        ){
                            product['productId'] = productId;
                        }
                    }
    
                    if(
                        resolvedParams['productQuantityField']
                    ){
                        let productQuantity = dotNotationMapper(productObj, resolvedParams['productQuantityField']);
                        if(
                            typeof productQuantity === 'number'
                        ){
                            product['quantity'] = productQuantity;
                        }
                    }
    
                    if(
                        resolvedParams['minQuantityField']
                    ){
                        let minQuantity = dotNotationMapper(productObj, resolvedParams['minQuantityField']);
                        if(
                            typeof minQuantity === 'number'
                        ){
                            product['minQuantity'] = minQuantity;
                        }
                    }
    
                    if(
                        resolvedParams['maxQuantityField']
                    ){
                        let maxQuantity = dotNotationMapper(productObj, resolvedParams['maxQuantityField']);
                        if(
                            typeof maxQuantity === 'number'
                        ){
                            product['maxQuantity'] = maxQuantity;
                        }
                    }
    
                    if(
                        product['maxQuantity']
                    ){
                        product['adjustableQuantity'] = true;
                    }
    
                    if(resolvedParams['productsDeletable']){
                        product['canDelete'] = true;
                    }
    
                    if(
                        !resolvedParams['useInhouzProducts']
                    ){
                        let productName = dotNotationMapper(productObj, resolvedParams['productNameField']);
                        if(typeof productName === 'string'){
                            product['name'] = productName;
                        }
    
                        let unitPrice = dotNotationMapper(productObj, resolvedParams['productUnitPriceField']);
                        if(typeof unitPrice === 'number'){
                            product['unitPrice'] = unitPrice;
                        }
                    }

                    if(
                        resolvedParams['useInhouzProducts'] && 
                        !product['productId']
                    ){
                        product['productId'] = Math.random().toFixed(7).slice(3);
                    }
    
                    products.push(product);
                }
    
                paymentDetails['products'] = products;

                if(
                    products.length === 0
                ){
                    variableCopy[variableName] = {
                        error : {
                            message : `A minimum of one product is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`
                        }
                    }
                    return {
                        updatedVariableObject : variableCopy,
                        hasError : true,
                        errorPayload : `A minimum of one product is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`,
                        hasReturnStatement : false,
                        returnStatement : undefined
                    }
                }
            }
    
            let flexScheduleDetails = [];
            if(
                (resolvedParams['paymentLinkType'] === 'flex-schedule') &&
                Array.isArray(resolvedParams['flexScheduleDetailsList']) && 
                resolvedParams['flexScheduleDetailsList'].length > 0
            ){
                for (let i = 0; i < resolvedParams['flexScheduleDetailsList'].length; i++){
                    let flexScheduleObject = resolvedParams['flexScheduleDetailsList'][i];
                    let parsedFlexScheduleObject = {};
                    if(
                        resolvedParams['flexScheduleTimestampField']
                    ){
                        let flexTimestamp = dotNotationMapper(flexScheduleObject, resolvedParams['flexScheduleTimestampField']);
                        if(
                            flexTimestamp && 
                            typeof flexTimestamp === 'number'
                        ){
                            parsedFlexScheduleObject['timestamp'] = flexTimestamp;
                        }
                    }
    
                    if(
                        resolvedParams['flexScheduleAmountField']
                    ){
                        let flexAmount = dotNotationMapper(flexScheduleObject, resolvedParams['flexScheduleAmountField']);
                        if(
                            flexAmount && 
                            typeof flexAmount === 'number'
                        ){
                            parsedFlexScheduleObject['amount'] = flexAmount;
                        }
                    }
    
                    if(
                        parsedFlexScheduleObject['timestamp'] && 
                        parsedFlexScheduleObject['amount']
                    ){
                        flexScheduleDetails.push(parsedFlexScheduleObject);
                    }
                }
    
                paymentDetails['flexScheduleDetails'] = flexScheduleDetails;

                if(
                    flexScheduleDetails.length === 0
                ){
                    variableCopy[variableName] = {
                        error : {
                            message : `A minimum of one schedule object with a timestamp and amount field is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`
                        }
                    }
                    return {
                        updatedVariableObject : variableCopy,
                        hasError : true,
                        errorPayload : `A minimum of one schedule object with a timestamp and amount field is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`,
                        hasReturnStatement : false,
                        returnStatement : undefined
                    }
                }
            }
    
            let splitPaymentDetails = [];
            if(
                (resolvedParams['paymentLinkType'] === 'split-payment') &&
                Array.isArray(resolvedParams['splitPaymentDetailsList']) && 
                resolvedParams['splitPaymentDetailsList'].length > 0
            ){
                for (let i = 0; i < resolvedParams['splitPaymentDetailsList'].length; i++){
                    let splitPaymentObject = resolvedParams['splitPaymentDetailsList'][i];
                    let parsedSplitPaymentObject = {};
                    if(
                        resolvedParams['splitPaymentRoleNameField']
                    ){
                        let roleName = dotNotationMapper(splitPaymentObject, resolvedParams['splitPaymentRoleNameField']);
                        if(
                            roleName && 
                            typeof roleName === 'string'
                        ){
                            parsedSplitPaymentObject['roleName'] = roleName;
                        }
                    }
    
                    if(
                        resolvedParams['splitPaymentAmountField']
                    ){
                        let splitAmount = dotNotationMapper(splitPaymentObject, resolvedParams['splitPaymentAmountField']);
                        if(
                            splitAmount && 
                            typeof splitAmount === 'number'
                        ){
                            parsedSplitPaymentObject['amount'] = splitAmount;
                        }
                    }
    
                    if(
                        parsedSplitPaymentObject['roleName'] && 
                        parsedSplitPaymentObject['amount']
                    ){
                        splitPaymentDetails.push(parsedSplitPaymentObject);
                    }
                }
    
                paymentDetails['splitPaymentDetails'] = splitPaymentDetails;

                if(
                    splitPaymentDetails.length === 0
                ){
                    variableCopy[variableName] = {
                        error : {
                            message : `A minimum of one split payment object with a roleName and amount field is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`
                        }
                    }
                    return {
                        updatedVariableObject : variableCopy,
                        hasError : true,
                        errorPayload : `A minimum of one split payment object with a roleName and amount field is required for the '${paymentDetails['paymentLinkType'].toLowerCase()}' payment type.`,
                        hasReturnStatement : false,
                        returnStatement : undefined
                    }
                }
            }
    
            document['paymentDetails'] = paymentDetails;
        }

        let payload = {
            document,
            templateId : resolvedParams['templateId'],
            sendOnCreate : resolvedParams['sendDocument'] ? true : false,
            companyId,
            functionGenerated : true
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let encryptedAccessHeader = '', clientEncryptedApiHeader='';
        if(resolvedParams['X-API-KEY']){
            clientEncryptedApiHeader = encryptDecrypt(
                JSON.stringify({
                    payload : {
                        'X-API-KEY' : resolvedParams['X-API-KEY'],
                        companyId,
                        queryKey : `${Math.random()}${Math.random()}${Math.random()}${new Date().getTime()}`,
                        environment : appEnvironment
                    },
                    expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                }),
                true
            );
        }else{
            if(user && user.isAuthenticated){
                encryptedAccessHeader = encryptDecrypt(
                    JSON.stringify({
                        payload : {
                            assetOwnerCompanyId : companyId,
                            companyId : user['companyId'],
                            email : user['email'],
                            subscriptionId : user['subscriptionId'],
                            subscriptionServiceId : user['subscriptionServiceId'],
                            environment : appEnvironment,
                            _id : user['_id'],
                            userId : user['_id'],
                            isInternal : (user['subscriberType'] === 'internal') || user['isInternal'] ? true : false,
                            isSystemUser : user['isSystemUser'] ? true : false
                        },
                        expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
                    }),
                    true
                );
            }
        }

        let response = await fetch(
            `${config.devServerProxy}/api/inhouzsign/document/initcreatefromtemplate`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                    'clientEncryptedAccessHeader' : encryptedAccessHeader,
                    'X-CE-API-KEY' : clientEncryptedApiHeader
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload,
                    documentData : resolvedParams['documentData'] || {}
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/createInhouzSignDocumentParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}

let paymentOptions = [
    'card',
    'us_bank_account',
    'applePay',
    'googlePay',
    'affirm',
    'afterpay_clearpay',
    'alipay',
    'au_becs_debit',
    'bacs_debit',
    'bancontact',
    'blik',
    'boleto',
    'eps',
    'fpx',
    'giropay',
    'grabpay',
    'ideal',
    'klarna',
    'konbini',
    'oxxo',
    'p24',
    'pix',
    'promptpay',
    'sepa_debit',
    'sofort',
    'wechat_pay'
];

const styleConfiguration = {
    hideLogo : false,
    hideCompanyName : false,
    hidePaymentLinkName : false,
    hidePaymentLinkDescription : false,
    productHeaderText : 'Summary',
    pageBackground : '',
    totalPriceColor : '#6c757d',
    paymentLinkNameColor : 'rgba(0,0,0,.87)',
    detailsPrimaryTextColor : 'rgba(0,0,0,.87)',
    detailsSecondaryTextColor : '#6c757d',
    detailsSectionHrBackgroundColor : 'rgba(0,0,0,.12)',
    detailsSectionInputBorder : '1px solid #ced4da',
    detailsSectionInputTextColor : '#495057',
    detailsSectionInputBackgroundColor : 'white',
    productPrimaryTextColor : 'rgba(0,0,0,.87)',
    productSecondaryTextColor : '#6c757d',
    productDeleteTextColor : '#17a2b8',
    productPriceTextColor : 'rgba(0,0,0,.87)',
    productBackgroundColor : 'white',
    productBorderColor : 'rgba(0,0,0,.12)',
    productImagePosition : 'inline',
    hideProductDescription : false,
    detailsBackground : '',
    companyNameTextColor : '#6c757d',
    stripeTheme : 'stripe',
    paymentSectionPrimaryTextColor : '#000000',
    paymentSectionSecondaryTextColor : '#6c757d',
    paymentSectionInputBorder : '1px solid #ced4da',
    paymentSectionInputTextColor : '#495057',
    paymentSectionInputBackgroundColor : 'white',
    paymentButtonBackgroundColor : 'rgb(0, 116, 212)',
    paymentButtonBackgroundTextColor : 'white',
    paymentSectionBackground : '',
    legalTextColor : '#17a2b8',
    fontFamily : 'Open Sans'
}