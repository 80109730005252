import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import dotNotationMapper from '../../data/dotNotationMapper';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        let variableCopy = parsedParameters['variableObject'];
        if(parsedParameters.error){
            variableCopy[variableName] = {
                error : {
                    message : 'Failed to parse function data.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedParams = parsedParameters['accumulator'];

        let bulkNotificationList = resolvedParams['pushNotificationList'] || [];
        if(
            !Array.isArray(bulkNotificationList) ||
            (bulkNotificationList.length === 0)
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'Invalid push notification list.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Invalid push notification list.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let notifications = [];
        for (let i = 0; i < bulkNotificationList.length; i++){
            let notificationObj = bulkNotificationList[i];
            let notification = {
                notificationTitle : dotNotationMapper(notificationObj, resolvedParams['notificationTitleField']) || '',
                notificationMessage : dotNotationMapper(notificationObj, resolvedParams['notificationMessageField']) || '',
                notificationLink : dotNotationMapper(notificationObj, resolvedParams['notificationLinkField']) || '',
                notificationIconUrl : dotNotationMapper(notificationObj, resolvedParams['notificationIconUrlField']) || '',
                pushSubscriptionObject : dotNotationMapper(notificationObj, resolvedParams['pushSubscriptionObjectField'], true) || {}
            }

            if(
                !notification.pushSubscriptionObject ||
                typeof notification.pushSubscriptionObject !== 'object' ||
                (
                    typeof notification.pushSubscriptionObject === 'object' && 
                    (Object.keys(notification.pushSubscriptionObject).length === 0)
                )
            ){
                continue;
            }else{
                notifications.push(notification);
            }
        }

        if(notifications.length === 0){
            variableCopy[variableName] = {
                error : {
                    message : 'Missing valid notifications.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Missing valid notifications.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let payload = {
            notifications,
            encryptedPayload : encryptDecrypt(JSON.stringify({
                payload : {
                    companyId
                },
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            })),
            environment : appEnvironment
        }

        let response = await fetch(
            `${config.devServerProxy}/api/pushnotificationparser/sendpushnotification`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                credentials : 'omit',
                body : JSON.stringify(payload)
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/bulkBrowserPushNotificationParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}