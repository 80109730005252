import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import dotNotationMapper from '../../data/dotNotationMapper';
import {isEmpty} from 'lodash';
import shortid from 'shortid';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            fieldList : paymentLinkFieldList
        });

        if(parsedParameters.error){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        let paymentLink = {
            systemProvidedCompanyId : companyId,
            paymentLinkType : 'fixed-schedule'
        };
        for (let k in resolvedParams){
            if(exactFields.includes(k)){
                paymentLink[k] = resolvedParams[k];
            }
        }

        //handle edge cases
        //handle payment methods
        let paymentMethods = [];
        if(
            resolvedParams['paymentMethod1'] && 
            typeof resolvedParams['paymentMethod1'] === 'string' && 
            paymentOptions.includes(resolvedParams['paymentMethod1'].toLowerCase())
        ){
            paymentMethods.push(resolvedParams['paymentMethod1'].toLowerCase());
        }
        if(
            resolvedParams['paymentMethod2'] && 
            typeof resolvedParams['paymentMethod2'] === 'string' && 
            paymentOptions.includes(resolvedParams['paymentMethod2'].toLowerCase())
        ){
            paymentMethods.push(resolvedParams['paymentMethod2'].toLowerCase());
        }
        if(
            resolvedParams['paymentMethod3'] && 
            typeof resolvedParams['paymentMethod3'] === 'string' && 
            paymentOptions.includes(resolvedParams['paymentMethod3'].toLowerCase())
        ){
            paymentMethods.push(resolvedParams['paymentMethod3'].toLowerCase());
        }

        paymentLink['paymentMethods'] = paymentMethods;

        if(paymentLink['collectInvoiceNumber']){
            paymentLink['collectInvoiceNumber'] = true;
            paymentLink['isSingleUse'] = true;
        }

        if(paymentLink['accessRestricted']){
            paymentLink['accessRestricted'] = true;
            if(
                paymentLink['passwordRestrictionType'] === 'userAssignedPassword' && 
                Array.isArray(resolvedParams['assignUserList'])
            ){
                let userPasswordList = [];
                for (let i = 0; i < resolvedParams['assignUserList'].length; i++){
                    let assignedUser = resolvedParams['assignUserList'][i];
                    let userObj = {}
                    if(
                        resolvedParams['assignedUserEmailField']
                    ){
                        userObj['email'] = dotNotationMapper(assignedUser, resolvedParams['assignedUserEmailField']);
                    }
                    if(
                        resolvedParams['assignedUserFirstNameField']
                    ){
                        userObj['firstName'] = dotNotationMapper(assignedUser, resolvedParams['assignedUserFirstNameField']);
                    }
                    if(
                        resolvedParams['assignedUserLastNameField']
                    ){
                        userObj['lastName'] = dotNotationMapper(assignedUser, resolvedParams['assignedUserLastNameField']);
                    }

                    if(!isEmpty(userObj)){
                        userPasswordList.push(userObj);
                    }
                }
                paymentLink['userPasswordList'] = userPasswordList;
            }

            if(
                paymentLink['setQuestionnaireRestriction']
            ){
                let accessQuestions = [];
                if(
                    resolvedParams['question1'] && 
                    resolvedParams['answer1'] && 
                    typeof resolvedParams['question1'] === 'string' && 
                    typeof resolvedParams['answer1'] === 'string'
                ){
                    accessQuestions.push({
                        question : resolvedParams['question1'],
                        answer : resolvedParams['answer1']
                    });
                }

                if(
                    resolvedParams['question2'] && 
                    resolvedParams['answer2'] && 
                    typeof resolvedParams['question2'] === 'string' && 
                    typeof resolvedParams['answer2'] === 'string'
                ){
                    accessQuestions.push({
                        question : resolvedParams['question2'],
                        answer : resolvedParams['answer2']
                    });
                }

                if(
                    resolvedParams['question3'] && 
                    resolvedParams['answer3'] && 
                    typeof resolvedParams['question3'] === 'string' && 
                    typeof resolvedParams['answer3'] === 'string'
                ){
                    accessQuestions.push({
                        question : resolvedParams['question3'],
                        answer : resolvedParams['answer3']
                    });
                }

                paymentLink['accessQuestions'] = accessQuestions;
            }
        }

        //get random slug
        let slugPayload = {
            companyId
        }

        let encryptedSlugPayload = encryptDecrypt(
            JSON.stringify({
                payload : slugPayload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let slugResponse = await fetch(
            `${config.devServerProxy}/api/paymentlink/generateslug`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload : encryptedSlugPayload
                })
            }
        )
        .then(res => {
            let jsonResponse = res.json();
            return jsonResponse;
        })
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(!slugResponse['slug']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to generate a unique slug.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        paymentLink['slug'] = slugResponse['slug'];
        paymentLink['styleConfiguration'] = styleConfiguration;

        let payload = {
            paymentLink,
            paymentPageDesignId : resolvedParams['paymentPageDesignId'],
            companyId,
            environment : appEnvironment,
            queryKey : `${shortid.generate()}${shortid.generate()}${shortid.generate()}${new Date().getTime()}`
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/paymentlink/servercreatepaymentlink`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(response['error']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : response['error']['message'],
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/createOneTimePaymentLinkParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}

const paymentLinkFieldList = [
    'name',
    'description',
    'defaultCurrency',
    'payoutAccountId',
    'slug',
    'paymentMethod1',
    'paymentMethod2',
    'paymentMethod3',
    'isSingleUse',
    'collectInvoiceNumber',
    'invoiceNumber',
    'invoiceDueDate',
    'collectFirstName',
    'collectLastName',
    'collectPhoneNumber',
    'collectCompanyName',
    'collectPurchaseOrderNumber',
    'collectTax',
    'addressToTax',
    'collectBillingAddress',
    'collectShippingAddress',
    'testWebhookUrl',
    'productionWebhookUrl',
    'emailMessage',
    'accessRestricted',
    'setPasswordRestriction',
    'passwordRestrictionType',
    'password',
    'assignUserList',
    'assignedUserEmailField',
    'assignedUserFirstNameField',
    'assignedUserLastNameField',
    'requestDocumentIdentityVerification',
    'documentIdentityVerificationTrustHours',
    'setQuestionnaireRestriction',
    'questionnaireIsCaseSensitive',
    'question1',
    'answer1',
    'question2',
    'answer2',
    'question3',
    'answer3',
    'showTermsOfUse',
    'termsOfUse',
    'useExternalTermsOfUse',
    'termsOfUseUrl',
    'collectTermsOfUseAcknowledgement',
    'paymentPageDesignId',
    'paymentAmount',
    'fixedScheduledPaymentCount',
    'fixedScheduleDayInterval',
    'categoryName',
    'projectId',
    'applySimpleInterest',
    'simpleInterestPercentage',
    'simpleInterestDurationCount',
    'simpleInterestDurationType',
    'delayInitialPayment',
    'initialPaymentDelayDayCount'
]

let exactFields = [
    'name',
    'description',
    'defaultCurrency',
    'payoutAccountId',
    'isSingleUse',
    'collectInvoiceNumber',
    'invoiceNumber',
    'invoiceDueDate',
    'collectFirstName',
    'collectLastName',
    'collectPhoneNumber',
    'collectCompanyName',
    'collectPurchaseOrderNumber',
    'collectTax',
    'addressToTax',
    'collectBillingAddress',
    'collectShippingAddress',
    'testWebhookUrl',
    'productionWebhookUrl',
    'emailMessage',
    'accessRestricted',
    'setPasswordRestriction',
    'passwordRestrictionType',
    'password',
    'requestDocumentIdentityVerification',
    'documentIdentityVerificationTrustHours',
    'setQuestionnaireRestriction',
    'questionnaireIsCaseSensitive',
    'showTermsOfUse',
    'termsOfUse',
    'useExternalTermsOfUse',
    'termsOfUseUrl',
    'collectTermsOfUseAcknowledgement',
    'paymentAmount',
    'fixedScheduledPaymentCount',
    'fixedScheduleDayInterval',
    'categoryName',
    'projectId',
    'applySimpleInterest',
    'simpleInterestPercentage',
    'simpleInterestDurationCount',
    'simpleInterestDurationType',
    'delayInitialPayment',
    'initialPaymentDelayDayCount'
]

let paymentOptions = [
    'card',
    'us_bank_account',
    'applePay',
    'googlePay',
    'affirm',
    'afterpay_clearpay',
    'alipay',
    'au_becs_debit',
    'bacs_debit',
    'bancontact',
    'blik',
    'boleto',
    'eps',
    'fpx',
    'giropay',
    'grabpay',
    'ideal',
    'klarna',
    'konbini',
    'oxxo',
    'p24',
    'pix',
    'promptpay',
    'sepa_debit',
    'sofort',
    'wechat_pay'
]

const styleConfiguration = {
    hideLogo : false,
    hideCompanyName : false,
    hidePaymentLinkName : false,
    hidePaymentLinkDescription : false,
    productHeaderText : 'Summary',
    pageBackground : '',
    totalPriceColor : '#6c757d',
    paymentLinkNameColor : 'rgba(0,0,0,.87)',
    detailsPrimaryTextColor : 'rgba(0,0,0,.87)',
    detailsSecondaryTextColor : '#6c757d',
    detailsSectionHrBackgroundColor : 'rgba(0,0,0,.12)',
    detailsSectionInputBorder : '1px solid #ced4da',
    detailsSectionInputTextColor : '#495057',
    detailsSectionInputBackgroundColor : 'white',
    productPrimaryTextColor : 'rgba(0,0,0,.87)',
    productSecondaryTextColor : '#6c757d',
    productDeleteTextColor : '#17a2b8',
    productPriceTextColor : 'rgba(0,0,0,.87)',
    productBackgroundColor : 'white',
    productBorderColor : 'rgba(0,0,0,.12)',
    productImagePosition : 'inline',
    hideProductDescription : false,
    detailsBackground : '',
    companyNameTextColor : '#6c757d',
    stripeTheme : 'stripe',
    paymentSectionPrimaryTextColor : '#000000',
    paymentSectionSecondaryTextColor : '#6c757d',
    paymentSectionInputBorder : '1px solid #ced4da',
    paymentSectionInputTextColor : '#495057',
    paymentSectionInputBackgroundColor : 'white',
    paymentButtonBackgroundColor : 'rgb(0, 116, 212)',
    paymentButtonBackgroundTextColor : 'white',
    paymentSectionBackground : '',
    legalTextColor : '#17a2b8',
    fontFamily : 'Open Sans'
}