import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import getCsrf from '../../cookies/getCsrf';

export default async function (
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, databaseId='',
            operationType='', query={}, transactionExpression={},
            optionsArray=[],
            collectionId='', useSchema=false, collectionName='',
            dynamicDB=false, dynamicDatabaseId={}
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !appEnvironment ||
            (
                appEnvironment && 
                typeof appEnvironment !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Runtime environment is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;
        let dbId = databaseId;
        if(dynamicDB){
            let resolvedDbId = await resolveExpression(
                dynamicDatabaseId,
                variableCopy,
                parameterObject,
                functionMap,
                variableIdMap
            );
            dbId = resolvedDbId.value;
            variableCopy = resolvedDbId.variableObject;
        }

        if(
            !dbId || 
            (
                dbId && 
                typeof dbId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Database is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !operationType ||
            (
                operationType && 
                typeof operationType !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Operation type is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedQuery = await resolveExpression(
            query,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedQueryValue = resolvedQuery.value;
        variableCopy = resolvedQuery.variableObject;

        if(
            !resolvedQueryValue
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Key is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedTransactionExpression = await resolveExpression(
            transactionExpression,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedTransactionValue = resolvedTransactionExpression.value;
        variableCopy = resolvedTransactionExpression.variableObject;

        let resolvedCollectionName = await resolveExpression(
            collectionName,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedCollectionNameValue = resolvedCollectionName.value;
        variableCopy = resolvedCollectionName.variableObject;

        let options = {}
        for (let i = 0; i < optionsArray.length; i++){
            let option = optionsArray[i];
            let {
                key='', value={}
            } = option;

            let resolvedOption = await resolveExpression(
                value,
                variableCopy,
                parameterObject,
                functionMap,
                variableIdMap
            );
            let resolvedOptionValue = resolvedOption.value;
            variableCopy = resolvedOption.variableObject;
            options[key] = resolvedOptionValue;
        }
        
        let dbData = {
            query : resolvedQueryValue,
            data : resolvedTransactionValue,
            options,
            // databaseId,
            // companyId,
            operationType,
            collectionName : resolvedCollectionNameValue,
            useSchema,
            collectionId,
            environment : appEnvironment,
            databaseType : functionType,
            user,
            timestamp : new Date().getTime()
        }

        let encryptedData = encryptDecrypt(
            JSON.stringify({
                dbData : {
                    companyId,
                    databaseId : dbId
                },
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );
        dbData['encryptedQuery'] = encryptedData;
        let response = await fetch(
            `${config.devServerProxy}/api/remoteexecution/webtransaction`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                    'x-csrfToken' : getCsrf()
                },
                credentials : 'include',
                body : JSON.stringify(dbData)
            }
        )
        .then(res => {
            let jsonResponse = res.json();
            return jsonResponse;
        })
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });
        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/mongoDbParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}