import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import shortid from 'shortid';
import dotNotationMapper from '../../data/dotNotationMapper';
import validator from 'validator';
import {uniq} from 'lodash';

export default async function (
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
            // companyId='',
            assetType='', subscriberType='', activeOnly={},
            assetId={}, dataToEnrich={}, emailField={}
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !appEnvironment ||
            (
                appEnvironment && 
                typeof appEnvironment !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Runtime environment is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;
        let resolvedAssetId = await resolveExpression(
            assetId,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedAssetIdValue = resolvedAssetId.value;
        variableCopy = resolvedAssetId.variableObject;

        if(
            !resolvedAssetIdValue ||
            (
                resolvedAssetIdValue && 
                typeof resolvedAssetIdValue !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Asset ID is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedDataToEnrich = await resolveExpression(
            dataToEnrich,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedDataToEnrichValue = resolvedDataToEnrich.value;
        variableCopy = resolvedDataToEnrich.variableObject;

        if(
            !Array.isArray(resolvedDataToEnrichValue)
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Data to enrich must be an array of objects.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedEmailField = await resolveExpression(
            emailField,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedEmailFieldValue = resolvedEmailField.value;
        variableCopy = resolvedEmailField.variableObject;

        if(
            !resolvedEmailFieldValue ||
            (
                resolvedEmailFieldValue && 
                typeof resolvedEmailFieldValue !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Email field is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let emails = [];
        for (let i = 0; i < resolvedDataToEnrichValue.length; i++){
            let dataObj = resolvedDataToEnrichValue[i];
            let email = dotNotationMapper(dataObj, resolvedEmailFieldValue);
            if(validator.isEmail(email)){
                emails.push(email);
            }
        }

        if(emails.length === 0){
            variableCopy[variableName] = resolvedDataToEnrichValue;
            return {
                hasError : false,
                errorPayload : undefined,
                hasReturnStatement : false,
                returnStatement : undefined,
                updatedVariableObject : variableCopy
            }
        }

        let payload = {
            functionType,
            companyId,
            queryKey : shortid.generate(),
            assetId : resolvedAssetIdValue,
            environment : appEnvironment === 'appbuilder' ? 'development' : appEnvironment,
            subscriberType,
            assetType,
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/subscriberdatafunction/getsubscribers`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload, 
                    emails : uniq(emails)
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(response['error']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve subscriber data',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let dataMap = {}, subscribers = response['subscribers'] || [];
        for (let i = 0; i < subscribers.length; i++){
            let subscriber = subscribers[i];
            dataMap[subscriber['email']] = subscriber;
        }

        let enrichedData = [];
        for (let i = 0; i < resolvedDataToEnrichValue.length; i++){
            let dataObj = resolvedDataToEnrichValue[i];
            let email = dotNotationMapper(dataObj, resolvedEmailFieldValue);
            let subscriberData = dataMap[email] || {};
            enrichedData.push({
                ...dataObj,
                ...subscriberData
            });
        }
        
        variableCopy[variableName] = enrichedData;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/getSubscriberCountParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}